<template>
  <a-modal
    v-model="visible"
    class="modal-delete"
    @ok="() => $emit('handleOpenModalDelete', false)"
    @cancel="() => $emit('handleOpenModalDelete', false)"
    :footer="null"
  >
    <div class="content flex-column d-flex align-items-center">
      <div class="content-trash-icon align-self-center"> <TrashIcon :width="'36'" :height="'40'" /> </div>

      <div
        class="mt-5"
        style="
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #000000;
        "
      >
        {{ $t('purchase.cart.caption_delete') }}
      </div>

      <div class="mt-3 d-flex justify-content-center align-items-center">
        <div
          class="mx-2 d-flex align-items-center justify-content-center"
          style="
            width: 87px;
            height: 33px;
            left: 119px;
            top: 159px;
            background: #FFFFFF;
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 5px;
            cursor: pointer;
          "
          @click.prevent="() => handleDeleteProduk()"
        >
          <span style="color: #000000" v-if="!loading">
            {{ $t('purchase.cart.yes') }}
          </span>
          <span style="color: #000000" v-if="loading"> <a-icon type="loading" /> </span>
        </div>
        <div
          class="mx-2 d-flex align-items-center justify-content-center"
          style="
            width: 87px;
            height: 33px;
            left: 216px;
            top: 159px;
            background: #000000;
            border-radius: 5px;
            cursor: pointer;
          "
          @click.prevent="() => $emit('handleOpenModalDelete', false)"
        >
          <span style="color: #FFFFFF">
            {{ $t('purchase.cart.cancel') }}
          </span>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import TrashIcon from '@/components/Icons/Trash.vue'

export default {
  props: ['visible', 'cart_item_id', 'warehouse_id', 'cart_id', 'isLoyalty'],
  components: {
    TrashIcon,
  },
  data: function () {
    return {
      loading: false,
    }
  },
  methods: {
    handleDeleteProduk() {
      this.loading = true
      this.$store.dispatch('purchase/DELETECART', {
        channel_id: this.isLoyalty ? this.$store.state.user.user_data.loyalty_channel_id : this.$store.state.user.user_data.channel_id,
        cart_item_id: this.cart_item_id,
        cart_id: this.cart_id,
      })
        .then(() => {
          this.$notification.success({
            message: this.$t('purchase.cart.successDeleteCart'),
          })
          this.$emit('fetchCarts')
          this.$emit('handleOpenModalDelete', false)
        })
        .catch((err) => {
          const { data } = err.response
          this.$notification.error({
            message: this.$t('purchase.cart.failedDeleteCart'),
            description: data && data.message ? data.message : 'Internal Server Error',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-modal .modal-delete {
  .ant-modal-content {
    border-radius: 5px !important;
    height: 250px !important;

    .ant-modal-header {
      background: #000000 !important;

      .ant-modal-title {
        color: #000000 !important;
      }
    }

    .ant-modal-body {
      .content {
        display: flex !important;
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;
        margin-top: -3rem;

        &-trash-icon {
          background: #FFFFFF !important;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
          padding: 1rem 1.5rem;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
