<script setup>
import { defineProps } from 'vue'

const props = defineProps(['active'])

const steps = [
  'Keranjang',
  'Pembayaran',
  'Status Pesanan',
]
</script>

<template>
  <div class="checkout-step">
    <div v-for="step, index in steps" :key="index" class="step" :class="{ active: props.active && (props.active - 1) === index }">
      <div class="counter">
        <span>{{  index + 1 }}</span>
      </div>
      <div>
        {{ step }}
      </div>
      <div v-if="index + 1 < steps.length" class="chevron">
        <i class="fe fe-chevron-right" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.checkout-step {
  display: flex;
  align-items: center;
  font-size: .9rem;
  gap: .5rem;

  @include media-breakpoint-up(md) {
    display: none;
  }

  > .step {
    display: flex;
    align-items: center;
    color: #D6D6D6;
    position: relative;
    padding-right: 1.2rem;

    &.active {
      color: #000;

      .counter {
        color: var(--biz-accent-3);
      }
    }
  }

  .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: .5rem;
    border-radius: 1rem;
    font-size: .8rem;
    // background: var(--biz-accent-2);
    background: currentColor;
    flex-shrink: 0;

    > span {
      color: white;
    }
  }

  .chevron {
    display: flex;
    align-items: center;
    margin: 0 auto;
    font-size: 90%;
    position: absolute;
    right: 0;
    color: #D6D6D6;
    transform: translateX(0%);
  }
}
</style>
