<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="19" viewBox="0 0 12 19" fill="none">
    <path
      d="M5.21866 0.63713C4.04982 0.80766 3.01599 1.30149 2.16689 2.10084C0.649888 3.52903 0.0175076 5.68552 0.529097 7.67503C0.696074 8.32517 0.870156 8.69466 1.37819 9.48336C3.91837 13.4197 5.68051 16.102 5.76223 16.1624C5.89368 16.2584 6.10684 16.2584 6.23829 16.1624C6.32 16.102 8.08214 13.4197 10.6223 9.48336C11.1304 8.69466 11.3044 8.32162 11.4714 7.67503C11.848 6.19711 11.6029 4.58063 10.8035 3.29455C9.9402 1.90544 8.65057 1.00306 7.08028 0.693974C6.5829 0.594498 5.70183 0.569629 5.21866 0.63713ZM7.01988 1.99071C7.33963 2.08663 7.61318 2.34953 7.74108 2.68349C7.77306 2.76875 7.79437 3.06718 7.80503 3.63206L7.81924 4.45984H8.25267C8.91703 4.45984 9.15861 4.55931 9.35401 4.92169C9.43572 5.0709 9.45348 5.14906 9.45348 5.32314C9.44638 5.71394 9.03071 9.37322 8.97742 9.50467C8.90281 9.68231 8.7536 9.84573 8.56176 9.95942L8.39833 10.0553L6.07486 10.066C3.51337 10.0767 3.55955 10.0802 3.28955 9.85639C3.00533 9.62191 3.00178 9.6077 2.76375 7.45121C2.64651 6.38185 2.54703 5.41551 2.54703 5.30538C2.54703 5.15261 2.56835 5.06024 2.64651 4.92524C2.84191 4.56286 3.08704 4.45984 3.74784 4.45984H4.18127L4.19548 3.63206C4.21325 2.75099 4.22391 2.67638 4.40865 2.40993C4.5401 2.21453 4.71773 2.08308 4.94511 2.00492C5.10142 1.94808 5.25774 1.94097 5.98605 1.93742C6.6433 1.93742 6.88133 1.94808 7.01988 1.99071Z"
      :fill="color || '#F5831F'" />
    <path
      d="M5.27878 3.02467C5.26456 3.03888 5.25391 3.36928 5.25391 3.75653V4.45996H5.99997H6.74604L6.73893 3.73876L6.72828 3.02112L6.01418 3.01046C5.62339 3.00691 5.29299 3.01401 5.27878 3.02467Z"
      :fill="color || '#F5831F'" />
    <path
      d="M3.63411 5.65664C3.64477 5.73124 3.72648 6.45599 3.8153 7.26601C3.90411 8.07603 3.98583 8.80078 3.99648 8.87183L4.0178 9.00684H6.00021H7.98616L8.00748 8.8896C8.01814 8.8292 8.0963 8.10445 8.18512 7.28377C8.27393 6.4631 8.35564 5.73124 8.3663 5.65664L8.38762 5.52519H6.00021H3.61279L3.63411 5.65664Z"
      :fill="color || '#F5831F'" />
    <path
      d="M3.5097 16.0422C1.89677 16.2554 0.592933 16.6711 0.138188 17.1116C-0.0501055 17.2928 -0.0465528 17.3816 0.152398 17.5628C0.649777 18.0211 1.96428 18.4261 3.61983 18.6321C6.867 19.0336 10.7643 18.5327 11.8301 17.577C12.0433 17.3851 12.0539 17.2999 11.8728 17.1258C11.3931 16.6604 10.1106 16.2518 8.46572 16.0422C7.63439 15.9356 7.73386 15.9001 7.41057 16.3975C7.25425 16.6355 7.06595 16.8771 6.98424 16.9446C6.38739 17.4278 5.60579 17.4278 5.01605 16.9446C4.93433 16.8771 4.74249 16.632 4.58972 16.3975L4.31261 15.9712L4.15274 15.9747C4.06392 15.9783 3.77615 16.0067 3.5097 16.0422Z"
      :fill="color || '#F5831F'" />
  </svg>
</template>

<script>
export default {
  name: 'LocationIcon',
  props: ['color'],
}
</script>
